@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

@import "dropdown-menu";

@import "mainBox-mensagem";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

// Container sizes
// --------------------------------------------------

  .container {
    width: 95%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  /* large devices */
  @media (min-width: 1200px) {
    .container {
       max-width: 1280px;
     }
  }
  /* extra large devices */
  @media screen and (min-width: 1800px) {
    .container {
        max-width: 1280px;
    }
  }

  
  // /* extra mobile devices */
  // @media screen and (min-width: 1800px) {
  //   .container {
  //       max-width: 1280px;
  //   }
  // }