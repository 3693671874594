// Here you can add other styles
* {
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #F2F2F8 !important;
}

body, input, button {
  font: 'Roboto', sans-serif;
}

select {
  option {
    left: 15px;
  }
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: unset;
    top: 0;
    left: 0;
    margin-left: 0;
  }
}

button:focus {
  border: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
}

.app-footer {
  background: transparent;
  // margin: 0 30px 0 230px !important;
  border-top: 1px solid #c9c9ce;
  margin: 0 auto 0 auto !important;
  padding: 1rem;
  width: 100%;
  bottom: 0;
}

.logoFooter {
  width: 50px;
  mix-blend-mode: luminosity;
}

.spanFooter {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #958f8f;
}

.limiteEmail{
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .app {
//   background: #e5e5e5;
// }

.app-header .navbar-brand {
  width: 100px !important;
}

.app-header .nav-item {
  display: block !important;
}

.sidebar {
  background: #ffffff;
  color: #6f6b6b;

  .nav {
    align-items: center;
  }

  .nav-title {
    color: #3e44c8;
    border-bottom: 1px solid #abb2f2;
    width: 80%;
    padding-left: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 144.14%;

    display: flex;
    align-items: center;
  }

  .nav-item {
    width: 80%;
    padding: 0.5rem 0;
    border-bottom: 1px solid #edeefa;
  }

  .nav-link {
    color: #6f6b6b;
    padding: 0.75rem 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    &:hover {
      background: #edeefa;
      border-radius: 8px;
    }

    .nav-icon {
      position: absolute;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  // .nav-icon {
  svg {
    // color: #6f6b6b !important;
    margin: 0 0.5rem 0 0;
    font-size: 20px;
  }

  .item-mov {
    .nav-link:hover {
      color: #1b6eec;
    }

    .nav-link {
      &.active {
        background: #1b6eec;
        border-radius: 8px;
        color: #fff;
      }
    }
  }

  .item-prod {
    .nav-link:hover {
      color: #ff9600;

    }

    .nav-link {
      &.active {
        background: #ff9600;
        border-radius: 8px;
        color: #fff;
      }
    }
  }

  .item-seg {
    .nav-link:hover {
      color: #4ac89b;
    }

    .nav-link {
      &.active {
        background: #4ac89b;
        border-radius: 8px;
        color: #fff;
      }
    }
  }

  .item-analises {
    .nav-link:hover {
      color: #6f6b6b;
    }

    .nav-link {
      &.active {
        background: #6f6b6b;
        border-radius: 8px;
        color: #fff;
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar-minimized {
    .sidebar {
      .nav {
        align-items: flex-start;
      }

      .nav-item {
        width: 28px;
        overflow: hidden;
        margin: 0 1rem;

        &:hover {
          .nav-link{
            background: #edeefa !important;
            border-radius: 8px;
          }
        }
      }

      .nav-link {
        position: relative;
        padding-left: 0.25rem;
        margin: 0;
        white-space: nowrap;
        border-left: 0;

        &:hover {
          background: #edeefa !important;
          border-radius: 8px;
        }
      }

      .item-mov {
        .nav-link:hover {
          color: #1b6eec;
        }
    
        .nav-link {
          &.active {
            background: #1b6eec !important;
            border-radius: 8px;
            color: #fff;
          }
        }
      }
    
      .item-prod {
        .nav-link:hover {
          color: #ff9600;
    
        }
    
        .nav-link {
          &.active {
            background: #ff9600 !important;
            border-radius: 8px;
            color: #fff;
          }
        }
      }
    
      .item-seg {
        .nav-link:hover {
          color: #4ac89b;
        }
    
        .nav-link {
          &.active {
            background: #4ac89b !important;
            border-radius: 8px;
            color: #fff;
          }
        }
      }
    
      .item-analises {
        .nav-link:hover {
          color: #6f6b6b;
        }
    
        .nav-link {
          &.active {
            background: #6f6b6b !important;
            border-radius: 8px;
            color: #fff;
          }
        }
      }
    }
  }
}
